import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { InductionConfigDetail } from 'src/app/models/inductionconfigdetail.model';
import { Job, PickupJob } from 'src/app/models/job.model';
import { Site } from 'src/app/models/site.model';
import { User } from 'src/app/models/user.model';
import { VisitReason } from 'src/app/models/visitreason.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { JobService } from 'src/app/services/job/job.service';
import { SiteService } from 'src/app/services/site/site.service';
import { LocalizationService } from '../internationalization/localization.service';
import { ReferenceService } from 'src/app/services/reference/reference.service';
import { FormService } from 'src/app/services/form/form.service';
import { InductionConfig } from 'src/app/models/inductionconfig.model';
import { CityWorkType, CompanyType, JobType } from 'src/app/shared/enums';
import { ClosedownService } from '@app/services/closedown/closedown.service';
import { AllocatedJobVisitReason } from '@app/models/allocatedjobvisitreason.model';
import { environment } from '@env/environment';

@Component({
  selector: 'app-reason-for-visit',
  templateUrl: './reason-for-visit.component.html',
  styleUrls: ['./reason-for-visit.component.scss']
})
export class ReasonForVisitComponent implements OnInit {
  @Input() inductionConfigDetail : any = '';
  jobsAllocated : Job[] = [];
  jobsParentUser : Job[] = [];
  currentUser : User | undefined;
  selectedSite : Site | undefined;
  reasonForVisitConfig : InductionConfigDetail | undefined;
  reasonsForVisit : VisitReason[] = [];
  nonCityFMWorkChecked : boolean = false;
  cityFMWorkChecked : boolean = false;
  nonCityFMWork :string = CityWorkType.NONCITYWORK;
  nonCityFMWorkLabel : string | undefined;
  inductionConfig: InductionConfig | undefined;
  pickupJob : PickupJob | undefined;
  fastLinkJob : Job | undefined;
  language : string = environment.defaultLang.toLowerCase();
  
  constructor(private _localizationService : LocalizationService,
              private _authService : AuthService,
              private _jobService : JobService,
              private _siteService : SiteService,
              private _referenceService : ReferenceService,
              private _formService : FormService,
              private _closedownService : ClosedownService,
              private _router : Router) { }

  ngOnInit(): void {

    this._authService.getUser().then(user => {
      if (user){
        this.currentUser = user;
        if (this.inductionConfigDetail){
          this.reasonForVisitConfig = this.inductionConfigDetail;
        }
        this.getFastLinkJob();
      }
    });

    this._localizationService.get().then((l) => {
      if(l){
        this.language = l.toLowerCase();
      }
    });
    
    this.nonCityFMWorkLabel = this._localizationService.translate("reasonforvisit_lbl_noncityfmwork");
  }

  async getFastLinkJob(){
    var processFlow = await this._closedownService.getProcessFlow();
    if (processFlow.jobId != '') {
      this._jobService.getJobsByJobNSubContractorId(processFlow.jobId, processFlow.subContResourceId).subscribe({
        next: jobs => {
          if (jobs && jobs.length > 0) {
              this.fastLinkJob = jobs[0];
              this.jobsAllocated.push(this.fastLinkJob);
              if (this.reasonForVisitConfig){
                this.fastLinkJob.reasonForVisitCheck = true;
                if (this.reasonForVisitConfig.answer == undefined){
                  const newVisitReason: AllocatedJobVisitReason = {
                    jobNumber: this.fastLinkJob.referenceNumber,
                    jobType: this.fastLinkJob.jobType,
                    calloutReferenceNumber: this.fastLinkJob.calloutReferenceNumber,
                    status: this.fastLinkJob.jobStatusName,
                    version: this.fastLinkJob.version
                  };
  
                  this.reasonForVisitConfig.answer = JSON.stringify([newVisitReason]);
                  this._siteService.addVisitReason(this.fastLinkJob);
                }
                this.cityFMWorkChecked = (this.reasonForVisitConfig.answer != undefined && this.reasonForVisitConfig.answer != "");
              }
              this.getSiteData();
          }
          else{
            alert(this._localizationService.translate('reasonforvisit_no_fastlink_job_found'));
            this._siteService.removeSignedInSite();
            this._router.navigate(['signin']);
          }
        },
        error: e => {
          if (e.error && e.error.errorCode) {
            alert(this._localizationService.translate(e.error.errorCode));
          }
          else {
            alert(this._localizationService.translate("reasonforvisit_error_get_job"));
          }
        }
      })
    }
    else{
      this.getSiteData();
    }

  }

  getVisitReasons(){
    if (this.inductionConfig?.visitorId) { 
        this._referenceService.getVisitReasons(this.inductionConfig.visitorId, true).subscribe({
          next: (visitReason) => {
            if (visitReason){
              this.reasonsForVisit = visitReason;
              this.reasonsForVisit.forEach(rs => {
                rs.displayDescription = rs.translations!
                  .find(s => s.languageCode.toLowerCase() == this.language)?.description ?? rs.description;
              })
              if (this.reasonForVisitConfig && this.reasonForVisitConfig.answer?.length > 0) {
                let visitReasonsArray = this.reasonForVisitConfig.answer.split(',');          
                visitReasonsArray.forEach(vra => {
                  let reason = this.reasonsForVisit.find(x => x.displayDescription == vra);
                  if (reason) reason.selected = true;
                });
              }
            } else console.log("No visit reasons retrieved"); 
          },
          error: (error) => {
            if (error.error && error.error.errorCode) {
              alert(this._localizationService.translate(error.error.errorCode));
            }
            else {
              alert(this._localizationService.translate("reasonforvisit_error_get_visitreason"));
            }
          }
        });
    }
  }

  private getInductionConfig(){
    this._formService.getInductionConfig().then(
      inductionConfigResult => {
        this.inductionConfig = inductionConfigResult;
        if (this.inductionConfig?.visitorId) { 
          this.getVisitReasons();
        }else{
          this.getUserJobs();
        }
      },
      error => {
        alert("Failed to get induction info.");
        this._siteService.removeSignedInSite();
        this._router.navigate(['signin']);
      });
  }

  private getSiteData() {
    this._siteService.getSignedInSite().then(site => {
      if (site) {
        this.selectedSite = site;
        this.getInductionConfig();
      } else {
        this._router.navigate(['signin']);
      }
     },
      error => {
        alert("Failed to get site info.");
        this._router.navigate(['signin']);
     });
  }

  private getUserJobs(): void {
    let userCompanies : string[] = [];
    if (this.currentUser){
      if (this.currentUser.companies){
        this.currentUser.companies.forEach(company => {
          if (company.companyType.toUpperCase() != CompanyType.SUBCON){
            userCompanies.push(company.referenceNumber);
          }
        });
      }
      if (this.selectedSite) {

        this._jobService.getJobsByCompanyRefNumber(this.selectedSite.id).subscribe({
            next: jobs => {
              if (jobs){
                let fastLinkJobId = (this.fastLinkJob) ? this.fastLinkJob.referenceNumber : undefined;
                jobs.forEach(job => {
                  let byPassAdd = false;
                  if (fastLinkJobId) {
                     byPassAdd = (fastLinkJobId.toLowerCase() == job.referenceNumber.toLowerCase());
                  }
                  if (!byPassAdd){
                    if (job.allocatedUserEmail){
                      this.jobsAllocated.push(job);
                    }
                    else{
                      var adminJob =  this.currentUser?.companies.find(c => c.referenceNumber == job.companyReferenceNumber && 
                                                                            c.companyType.toUpperCase() == CompanyType.SELF);
                      if (adminJob){
                        this.jobsAllocated.push(job);
                      }
                      else{
                        this.jobsParentUser.push(job);
                      }
                    }
                  }
                })
                this.checkSelectedJob();
             } 
            },
            error: e => {
              if (e.error && e.error.errorCode) {
                alert(this._localizationService.translate(e.error.errorCode));
              }
              else {
                alert(this._localizationService.translate("reasonforvisit_error_get_job"));
              }
            }
        })    
      }
    }  

  }

  pickupJobOnClick(selectedJob : Job){
    if (this.currentUser){
      let company = this.currentUser.companies.find(c => c.referenceNumber == selectedJob.companyReferenceNumber);
      if (company){
        this.pickupJob = {
          jobId : selectedJob.referenceNumber,
          resourceId : selectedJob.companyReferenceNumber,
          subContractorEmail : this.currentUser.emailAddress,
          companyName : company.name,
          technicianName : this.currentUser.firstName + ' ' + this.currentUser.lastName,
          mobileNumber : this.currentUser.mobileNumber,
          jobType : selectedJob.jobType
        }

        this._jobService.pickupJob(this.pickupJob).subscribe(
          {
            next : result => {
              if(result){    
                this.jobsAllocated.push(selectedJob);
                this.jobsParentUser = this.jobsParentUser.filter(j => j !== selectedJob);
              }
            },
            error : error =>{
              if (error.error && error.error.errorCode) {
                alert(this._localizationService.translate(error.error.errorCode));
              }
              else {
                alert(this._localizationService.translate("reasonforvisit_pickup_job_save_error"));
              }
            }
          }
        )
      }
    }
   
  }

  checkSelectedJob(){
    if (this.reasonForVisitConfig){
      if (this.reasonForVisitConfig.answer != undefined && this.reasonForVisitConfig.answer != ""){
        let configAnswer = this.reasonForVisitConfig.answer.toLowerCase();
        if (configAnswer.indexOf(this.nonCityFMWork) > -1){
          this.nonCityFMWorkChecked = true;
        }
        else{
          this.jobsAllocated.forEach(job => {
            if (configAnswer.indexOf(job.referenceNumber) > -1){
              job.reasonForVisitCheck = true;
            }
          })
          this.cityFMWorkChecked = true;
        }
      }
    }
  }

  onJobCheck(checked: boolean, job: Job) {
    if (this.reasonForVisitConfig) {
      if (checked) {
        const newVisitReason: AllocatedJobVisitReason = {
          jobNumber: job.referenceNumber,
          jobType: job.jobType,
          calloutReferenceNumber: job.calloutReferenceNumber,
          status: job.jobStatusName,
          version: job.version
        };
        if (this.reasonForVisitConfig.answer != undefined && this.reasonForVisitConfig.answer != "") {
          let existingVisitReasons: AllocatedJobVisitReason[] = JSON.parse(this.reasonForVisitConfig.answer);
          existingVisitReasons.push(newVisitReason);
          this.reasonForVisitConfig.answer = JSON.stringify(existingVisitReasons);
        }
        else {
          this.reasonForVisitConfig.answer = JSON.stringify([newVisitReason]);
        }
        job.reasonForVisitCheck = true;
        this._siteService.addVisitReason(job);
      }
      else {
        if (this.reasonForVisitConfig.answer != undefined && this.reasonForVisitConfig.answer != "") {
          this._siteService.removeVisitReason(job);
          const existingVisitReasons: AllocatedJobVisitReason[] = JSON.parse(this.reasonForVisitConfig.answer);
          const updatedReasons = existingVisitReasons.filter(reason => reason.jobNumber !== job.referenceNumber);

          this.reasonForVisitConfig.answer = updatedReasons.length > 0 ? JSON.stringify(updatedReasons) : "";
        }
      }
      this.cityFMWorkChecked = (this.reasonForVisitConfig.answer != undefined && this.reasonForVisitConfig.answer != "");
    }
  }

  nonCityFMWorkCheck(checked : boolean){
    this.nonCityFMWorkChecked = checked;
    if (this.reasonForVisitConfig){
      if (checked){
        this.reasonForVisitConfig.answer = this.nonCityFMWork;
        this._siteService.removeAllVisitReasons();  
        this._siteService.addVisitorTypeReason(this.nonCityFMWork);
      }
      else{
        this.reasonForVisitConfig.answer = "";
        this._siteService.removeAllVisitReasons();  
      }
    }      
  }

  onReasonForVisitSelect(vr : VisitReason){
    if (this.reasonForVisitConfig && this.reasonsForVisit) {
      vr.selected = !vr.selected;
      this.reasonForVisitConfig.answer = this.reasonsForVisit
        .filter(x => x.selected).map(f => {return f.displayDescription}).join(",");

      this._siteService.removeAllVisitReasons();  
      this.reasonForVisitConfig.answer.split(',').forEach(e => {
         this._siteService.addVisitorTypeReason(e);
      });    
    }
  }

  isReactiveJob(job : Job){
    return (job.jobType == JobType.REACTIVE_JOB) ? true : false;
  }

  isPPMJob(job : Job){
     return (job.jobType == JobType.PPM_JOB) ? true : false;
  }
}