import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RapidGlobalInfo, SiteInductionInfo, User, UserDocument, Module, InviteUser, UpdatedUserCompanies, SignInSignOut, Role, SignInInfo } from '../../models/user.model';
import { BaseService } from '../base/base.service';
import { Notification } from '../../models/user.model';
import { StorageKey } from '@app/shared/enums';
import { Preferences } from '@capacitor/preferences';
import { CityCompliance } from '@app/models/compliance.model';

@Injectable({
    providedIn: 'root'
})
export class UserService extends BaseService {
    //readonly BASE_URL = `https://localhost:7062/v1/gateway/users/`;
    readonly BASE_URL = `${environment.gatewayAPI_V1}/users/`;
    readonly KEY_SIGNIN = StorageKey[StorageKey.SIGNIN];
    readonly KEY_OUTOFRANGE = StorageKey[StorageKey.OUTOFRANGE];
    readonly KEY_OUTOFRANGERETRYCOUNT = StorageKey[StorageKey.OUTOFRANGERETRYCOUNT];
    readonly KEY_OUTOFRANGEPREVIOUSNOTIFICATIONTIME = StorageKey[StorageKey.OUTOFRANGEPREVIOUSNOTIFICATIONTIME];
    readonly KEY_OUTOFRANGENOTIFICATIONSUCCESSFUL = StorageKey[StorageKey.OUTOFRANGENOTIFICATIONSUCCESSFUL];
    readonly KEY_SIGNINMETHOD = StorageKey[StorageKey.SIGNINMETHOD];
    
    constructor(private httpClient: HttpClient) {
        super();
    }

    //#region UsersController

    public validateUserPassword(userEmail: string, userPassword: string): Observable<User> {
        const url = `${this.BASE_URL}users/validateuserpassword?userEmail=${userEmail}&userPassword=${userPassword}`;
        return this.httpClient.get<User>(url).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    public getUserModules(): Observable<Module[]> {
        const url = `${this.BASE_URL}users/GetUserModules`;
        return this.httpClient.get<Module[]>(url).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    public updateRegisterStatus(id: string, registerStatusInJson: string): Observable<boolean> {
        const url = `${this.BASE_URL}users/updateregisterstatus?id=${id}`;
        return this.httpClient.put<boolean>(url, JSON.stringify(registerStatusInJson)).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    public updateUserPhoto(userId: string, fileStorageId: string): Observable<boolean> {
        const url = `${this.BASE_URL}users/UpdateUserPhoto?id=${userId}`;
        let photoUrl = {
            Photo: fileStorageId
        };
        return this.httpClient.put<boolean>(url, JSON.stringify(photoUrl)).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    public verifyUser(token: string): Observable<string> {
        const url = `${this.BASE_URL}users/VerifyUser?id=${token}`;
        return this.httpClient.get<string>(url).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    public getUserByEmail(): Observable<User> {
        const url = `${this.BASE_URL}users/getuserprofilebyemail`;
        return this.httpClient.get<User>(url).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    public getUserByEmailWithDetails(): Observable<User> {
      const url = `${this.BASE_URL}users/getuserprofilebyemailwithdetails`;
      return this.httpClient.get<User>(url).pipe(
          tap(_ => _),
          catchError(this.handleError));
  }

    public getInternalTechnicians(userId: string): Observable<User[]> {
        const url = `${this.BASE_URL}users/GetInternalTechnicians?id=${userId}`;
        return this.httpClient.get<User[]>(url).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    public inviteUser(user: InviteUser): Observable<InviteUser> {
        const url = `${this.BASE_URL}users/InviteUser`;
        return this.httpClient.post<InviteUser>(url, user).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    public updateCompanies(userId: string, userEmail: string, inviterEmail: string, updatedUserCompanies: UpdatedUserCompanies): Observable<User> {
        const url = `${this.BASE_URL}users/UpdateCompanies?id=${userId}&userEmail=${userEmail}&inviterEmail=${inviterEmail}`;
        return this.httpClient.put<User>(url, updatedUserCompanies).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    public updateUserLoginInfo(appVersion: string): Observable<boolean> {
        const url = `${this.BASE_URL}users/UpdateUserLoginInfo`;
        return this.httpClient.put<boolean>(url, JSON.stringify(appVersion)).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    public deactivateUser(): Observable<boolean> {
        const url = `${this.BASE_URL}users/DeactivateUser`;
        return this.httpClient.post<boolean>(url, {}).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    //#endregion

    //#region RapidGlobalsController

    public addRapidGlobal(userId: string, rapidGlobalInfo: RapidGlobalInfo): Observable<RapidGlobalInfo> {
        const url = `${this.BASE_URL}rapidglobals/addrapidglobal?userId=${userId}`;
        return this.httpClient.post<RapidGlobalInfo>(url, rapidGlobalInfo).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    public editRapidGlobal(id: string, rapidGlobalInfo: RapidGlobalInfo): Observable<RapidGlobalInfo> {
        const url = `${this.BASE_URL}rapidglobals/${id}`;
        return this.httpClient.put<RapidGlobalInfo>(url, rapidGlobalInfo).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    public deleteRapidGlobal(id: string) {
        const url = `${this.BASE_URL}rapidglobals/${id}`;
        return this.httpClient.delete(url).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

      public validateCityCompliance(rapidGlobalId: string, siteId: string): Observable<CityCompliance> {
        const url = `${this.BASE_URL}rapidglobals/ValidateCityCompliance?id=${rapidGlobalId}&siteId=${siteId}`; 
        return this.httpClient.post<CityCompliance>(url, '{}').pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    //#endregion

    //#region SiteInductionController

    public addInduction(userId: string, inductionInfo: SiteInductionInfo): Observable<SiteInductionInfo> {
        const url = `${this.BASE_URL}siteinductions/addinduction?userId=${userId}`;
        return this.httpClient.post<SiteInductionInfo>(url, inductionInfo).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    public editInduction(id: string, inductionInfo: SiteInductionInfo): Observable<SiteInductionInfo> {
        const url = `${this.BASE_URL}siteinductions/${id}`;
        return this.httpClient.put<SiteInductionInfo>(url, inductionInfo).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    public deleteInduction(id: string) {
        const url = `${this.BASE_URL}siteinductions/${id}`;
        return this.httpClient.delete(url).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    //#endregion

    //#region UserDocumentController

    public getDocument(id: string): Observable<UserDocument> {
        const url = `${this.BASE_URL}userDocuments/${id}`;
        return this.httpClient.get<UserDocument>(url).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    public UploadDocument(userDocument: UserDocument): Observable<UserDocument> {
        const url = `${this.BASE_URL}userdocuments/upload`;
        return this.httpClient.post<UserDocument>(url, JSON.stringify(userDocument)).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    public deleteDocument(id: string) {
        const url = `${this.BASE_URL}userDocuments/${id}`;
        return this.httpClient.delete(url).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    //#endregion

    //#region SignInSignOutsController

    public addSignIn(signInSignOut: SignInSignOut): Observable<SignInSignOut> {
        const url = `${this.BASE_URL}signinsignouts/createsignin`;
        return this.httpClient.post<SignInSignOut>(url, signInSignOut).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    public addSignOut(signInSignOut: SignInSignOut): Observable<SignInSignOut> {
        const url = `${this.BASE_URL}signinsignouts/createsignout`;
        return this.httpClient.post<SignInSignOut>(url, signInSignOut).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    public getSignInInfo(): Observable<SignInSignOut | null> {
      const url = `${this.BASE_URL}signinsignouts/getsignin`;
      return this.httpClient.get<SignInSignOut>(url).pipe(
          tap(_ => _),
          catchError(this.handleError));
    }

    public checkSignOut(): Observable<boolean> {
      const url = `${this.BASE_URL}signinsignouts/CheckSignOut`;
      return this.httpClient.get<boolean>(url).pipe(
          tap(_ => _),
          catchError(this.handleError));
    }

    public async getSignIn(): Promise<SignInInfo> {
        const item = await Preferences.get({ key: this.KEY_SIGNIN });
        var obj;
        if (item.value != null) {
          obj = JSON.parse(item.value);
        }
        return obj;
    }

    async setSignIn(signInInfo: SignInInfo) {
        await Preferences.set({
            key: StorageKey[this.KEY_SIGNIN],
            value: JSON.stringify(signInInfo),
        });
    }

    async removeSignIn() {
        await Preferences.remove({ key: StorageKey[this.KEY_SIGNIN] });
    }

    async setSignInMethod(signInMethod: string | undefined) {
        if (signInMethod){
            await Preferences.set({
                key: StorageKey[this.KEY_SIGNINMETHOD],
                value: signInMethod,
            });
        }
    }

    async getSignInMethod(): Promise<string> {
        const item = await Preferences.get({ key: this.KEY_SIGNINMETHOD });
        if (item.value != null) {
           return item.value;
        }

        return "";
    }

    async removeSignInMethod() {
        await Preferences.remove({ key: StorageKey[this.KEY_SIGNINMETHOD] });
    }

    //#endregion

    //#region NotificationsController

    public registerForNotification(notification: Notification): Observable<Notification> {
        const url = `${this.BASE_URL}notifications/add`;
        return this.httpClient.post<Notification>(url, JSON.stringify(notification), {headers:{skip:"true"}}).pipe(
            tap(_ => _),
            catchError(this.handleError));      
    }

    public pushNotification(id: string){
        const url = `${this.BASE_URL}notifications/${id}`;    

        return this.httpClient.put(url, '{}').pipe(
            tap(_ => _),
            catchError(this.handleError));      
    }

    public async getOutOfRange(): Promise<string> {
        const item = await Preferences.get({ key: this.KEY_OUTOFRANGE });
        if (item.value != null) {
           return item.value;
        }
        return "";
      }
    
    async setOutOfRange(outOfRange: string | undefined) {
        if (outOfRange){
            await Preferences.set({
                key: StorageKey[this.KEY_OUTOFRANGE],
                value: outOfRange,
            });
        }
    }

    async removeOutOfRange() {
        await Preferences.remove({ key: StorageKey[this.KEY_OUTOFRANGE] });
    }

    public async setOutOfRangeRetryCount(retryCount: string) : Promise<void>{
        await Preferences.set({
            key: StorageKey[this.KEY_OUTOFRANGERETRYCOUNT],
            value: retryCount,
        });
    }

    public async getOutOfRangeRetryCount() : Promise<string>{
        const item = await Preferences.get({ key: this.KEY_OUTOFRANGERETRYCOUNT });
        if (item.value != null) {
           return item.value;
        }
        return "";
    }

    public async removeOutOfRangeRetryCount() : Promise<void>{
        await Preferences.remove({ key: StorageKey[this.KEY_OUTOFRANGERETRYCOUNT] });
    }

    public async clearOutOfRangeSettings(): Promise<void>{
        await this.removeOutOfRangeRetryCount();
    }
    //#endregion

    //#region Admin Management

    public getAdminRoles(): Observable<Role[]> {
        const url = `${this.BASE_URL}users/getAdminRoles`;
        return this.httpClient.get<Role[]>(url).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    public getAdminUsers(): Observable<User[]> {
        const url = `${this.BASE_URL}users/GetAdminUsers`;
        return this.httpClient.get<User[]>(url).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }

    public addAdminUser(emailAddress: string, roleIds: string[]): Observable<User> {
        const url = `${this.BASE_URL}users/CreateAdmin?userEmail=${emailAddress}`;
        return this.httpClient.post<User>(url, roleIds).pipe(
            tap(_ => _),
            catchError(this.handleError));
    }


    //#endregion
}