import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { RapidGlobalInfo } from 'src/app/models/user.model';
import { SiteInductionInfo } from 'src/app/models/user.model';
import { SiteService } from 'src/app/services/site/site.service';
import { Site } from 'src/app/models/site.model';
import { DatePipe } from '@angular/common';
import { InductionConfigDetail } from 'src/app/models/inductionconfigdetail.model';
import { environment } from 'src/environments/environment';
import { FileStorageService } from 'src/app/services/filestorage/filestorage.service';
import { ComplianceCheckAnswerModel } from '@app/models/inductionqna.model';
import { UserService } from '@app/services/user/user.service';
import { ConfigurationService } from '@app/services/configuration/configuration.service';
import { CityComplianceStatus, DefaultCompanyType, DisplayedComplianceStatus } from 'src/app/shared/enums';
import { LocalizationService } from '../internationalization/localization.service';

@Component({
  selector: 'app-site-compliance-check',
  templateUrl: './site-compliance-check.component.html',
  styleUrls: ['./site-compliance-check.component.scss']
})
export class SiteComplianceCheckComponent implements OnInit {
  public siteComplianceCheckFormGroup!: UntypedFormGroup;
  currentUser: User | undefined;
  phoneNumber: string | undefined;
  profileImgLink: string | undefined;
  public rapidGlobalInfoList: RapidGlobalInfo[] = [];
  public siteInductionInfoList: SiteInductionInfo[] = [];
  withJob = false;
  fullname: string | undefined;
  companyName: string | undefined;
  currentSite: Site | undefined;
  public rapidGlobalInfo: RapidGlobalInfo[] = [];
  public siteInductionInfo: SiteInductionInfo[] = [];
  selectedRapidGlobalInfo: RapidGlobalInfo | undefined;;
  selectedSiteInductionInfo: SiteInductionInfo | undefined;;
  siteComplianceCheckConfig: InductionConfigDetail | undefined;
  @Input() inductionConfigDetail: any = '';
  profileInductionRequired: boolean = false;
  profileRapidGlobalRequired: boolean = false;

  statusCompliant = this._localizationService.translate(DisplayedComplianceStatus.statusCompliant);
  statusNotCompliant = this._localizationService.translate(DisplayedComplianceStatus.statusNotCompliant);
  statusNotApplicable = this._localizationService.translate(DisplayedComplianceStatus.statusNotApplicable);
  complianceErrorMessage: string = "";
  notComplianceIcon: string = this._localizationService.translate('site_compliance_status_error_message_icon');
  defaultNoCompanySpecified: string = DefaultCompanyType.NO_COMPANY_SPECIFIED;
  defaultCityFMCompanyName: string = DefaultCompanyType.CITYFM;
  defaultCompanyName: string = this.defaultNoCompanySpecified;

  @Output() showComplianceError = new EventEmitter<boolean>();

  constructor(private _authService: AuthService,
    private _siteService: SiteService,
    public _router: Router,
    private formBuilder: UntypedFormBuilder,
    public datepipe: DatePipe,
    private _fileStorageService: FileStorageService,
    private _userService: UserService,
    private _configurationService: ConfigurationService,
    private _localizationService: LocalizationService) {
  }

  ngOnInit(): void {
 
    this._configurationService.getProfileInductionRequired()
      .then(inductionRequirementValue => {
        this.profileInductionRequired = inductionRequirementValue;
      });

    this._configurationService.getProfileRapidGlobalRequired()
      .then(rapidglobalrequired => {
        this.profileRapidGlobalRequired = rapidglobalrequired;
      });

      this._authService.getUserRoles().then(
        r => {
          const cityFMRoles = r.filter(s => s == this.defaultCityFMCompanyName);
          if(cityFMRoles && cityFMRoles.length > 0){
            this.defaultCompanyName = this.defaultCityFMCompanyName;
          }
        }).finally(() =>{
          this.loadSite();
          this.loadUser();
        });
        this.siteComplianceCheckFormGroup = this.initSiteComplianceCheckForm();
  }

  private initSiteComplianceCheckForm(): UntypedFormGroup {
    const siteComplianceCheckGroup = this.formBuilder.group({
    });

    return siteComplianceCheckGroup;
  }

  loadUser(){
    this._authService.getUser().then(
      user => {
        this.currentUser = user;
        this.fullname = `${user.firstName} ${user.lastName}`;
        this.phoneNumber = user.mobileNumber;
        this.getProfileImageLink(user.photo);
        this.rapidGlobalInfoList = user.rapidGlobalInfos
        this.siteInductionInfoList = user.siteInductionInfos;
        this.siteInductionInfo = this.siteInductionInfoList.filter(sii => sii.brandId === this.currentSite?.brand?.id);
        if (this.siteInductionInfo.length == 1) {
          this.selectedSiteInductionInfo = this.siteInductionInfo[0];
        }
        else {
          this.selectedSiteInductionInfo = undefined;
        }

        if (this.inductionConfigDetail) {
          this.siteComplianceCheckConfig = this.inductionConfigDetail;
        }

        if (this.siteComplianceCheckConfig?.answer) {
          this.companyName = this.getComplianceCheckAnswerCompanyName();
        }
        else {
          if (this.rapidGlobalInfoList.length > 0) {
            this.companyName = this.rapidGlobalInfoList[0].companyName;
            this.selectedRapidGlobalInfo = this.rapidGlobalInfoList[0];
          }
          else {
            this.companyName = this.defaultCompanyName;
          }
          this.siteComplianceCheckConfig!.answer = this.getComplianceCheckAnswer();
        }

        this.rapidGlobalInfo = this.rapidGlobalInfoList.filter(rgi => rgi.companyName === this.companyName);

        if (this.rapidGlobalInfo.length == 1) {
          this.selectedRapidGlobalInfo = this.rapidGlobalInfo[0];
          if (this.rapidGlobalInfo[0].companyId) {
            this.validateCityCompliance(this.selectedRapidGlobalInfo.id!);
          }
          else {
            if (this.siteComplianceCheckConfig && this.companyName) {
              this.siteComplianceCheckConfig.answer = this.getComplianceCheckAnswer();
            }
          }
        }
        else {
          if (user.rapidGlobalInfos.length == 1) {

            this.companyName = user.rapidGlobalInfos[0].companyName;
            this.selectedRapidGlobalInfo = user.rapidGlobalInfos[0];

            if (user.rapidGlobalInfos[0].companyId) {
              this.validateCityCompliance(this.selectedRapidGlobalInfo.id!);
            } else {
              if (this.siteComplianceCheckConfig && this.companyName) {
                this.siteComplianceCheckConfig.answer = this.getComplianceCheckAnswer();
              }
            }
          }
          else {
            this.selectedRapidGlobalInfo = undefined;
          }
        }
      },
      error => {
        console.log("Failed to get user");
      });
  }

  loadSite() {
    this._siteService.getSignedInSite().then(site => {
      if (site) {
        this.currentSite = site;
      } else {
        this._siteService.removeSignedInSite();
        this._router.navigate(['signin']);
      }
    },
      error => {
        alert("Failed to get site info.");
        this._siteService.removeSignedInSite();
        this._router.navigate(['signin']);
      });
  }

  getComplianceCheckAnswer(): string {
    var answer = new ComplianceCheckAnswerModel();
    answer.companyName = this.companyName == null || this.companyName == undefined ? "" : this.companyName;
    answer.inductionNo = this.selectedSiteInductionInfo?.inductionId == null || this.selectedSiteInductionInfo?.inductionId == undefined ? "" : this.selectedSiteInductionInfo?.inductionId;
    answer.inductionGuid = this.selectedSiteInductionInfo?.id == null || this.selectedSiteInductionInfo?.id == undefined ? "" : this.selectedSiteInductionInfo?.id

    answer.rapidGlobalId = this.selectedRapidGlobalInfo?.cityComplianceId == null || this.selectedRapidGlobalInfo?.cityComplianceId == undefined ? "" : this.selectedRapidGlobalInfo?.cityComplianceId;
    answer.rapidGlobalGuid = this.selectedRapidGlobalInfo?.id == null || this.selectedRapidGlobalInfo?.id == undefined ? "" : this.selectedRapidGlobalInfo?.id
    answer.companyId = this.selectedRapidGlobalInfo?.companyId == null || this.selectedRapidGlobalInfo?.companyId == undefined ? "" : this.selectedRapidGlobalInfo?.companyId;
    answer.statusId = this.selectedRapidGlobalInfo?.statusId == null || this.selectedRapidGlobalInfo?.statusId == undefined ? -1 : this.selectedRapidGlobalInfo?.statusId;
    
    if(answer.companyName == this.defaultCityFMCompanyName){
      answer.statusId = CityComplianceStatus.COMPLIANT;
    }else if(this.checkIfComplianceShouldDisplayNotEnable()){
      answer.statusId = CityComplianceStatus.NOTAPPLICABLE;
    }
    
    return JSON.stringify(answer);
  }

  checkIfComplianceShouldDisplayNotEnable(){

    if(this.companyName == this.defaultNoCompanySpecified){
      return true;
    }else if(this.selectedRapidGlobalInfo && this.selectedRapidGlobalInfo.status == ""){
      return true;
    }

    return false;
  }

  getComplianceCheckAnswerCompanyName(): string {
    if (this.siteComplianceCheckConfig?.answer) {
      var model = JSON.parse(this.siteComplianceCheckConfig?.answer) as ComplianceCheckAnswerModel;
      return model.companyName;
    }
    return "";
  }

  selectCompany(selectedCompanyName: string) {
    this.companyName = selectedCompanyName;
    this.rapidGlobalInfo = this.rapidGlobalInfoList.filter(rgi => rgi.companyName === this.companyName);

    if (!this.rapidGlobalInfo || this.rapidGlobalInfo.length < 1) {
      this.selectedRapidGlobalInfo = undefined;
    }
    else {
      this.selectedRapidGlobalInfo = this.rapidGlobalInfo[0];
      if (this.rapidGlobalInfo[0].companyId) {
        this.validateCityCompliance(this.selectedRapidGlobalInfo.id!);
      }
    }

    if (this.siteComplianceCheckConfig && this.companyName) {
      this.siteComplianceCheckConfig.answer = this.getComplianceCheckAnswer();
    }
    if(this.companyName === this.defaultCompanyName || 
        (this.selectedRapidGlobalInfo != undefined && this.selectedRapidGlobalInfo.companyId == null)){
      this.showComplianceError.emit(false);
      // complianceErrorMessage needs to be cleared if company added from User Profile is selected because validateCityCompliance() will not be called
      this.complianceErrorMessage = '';
    }
  }

  private validateCityCompliance(rapidGlobalId: string): void {
    this.complianceErrorMessage = "";
    if (this.currentSite) {
      this._userService.validateCityCompliance(rapidGlobalId, this.currentSite.id).subscribe({
        next: (s) => {
          if (this.selectedRapidGlobalInfo) {
            this.selectedRapidGlobalInfo.id = rapidGlobalId;
            this.selectedRapidGlobalInfo!.cityComplianceId = s.resourceId;

            if(s.cityComplianceStatus)
              this.selectedRapidGlobalInfo.statusId = s.cityComplianceStatus;

            switch (s.cityComplianceStatus) {
              case CityComplianceStatus.COMPLIANT:
                this.selectedRapidGlobalInfo!.status = this.statusCompliant;
                this.showComplianceError.emit(false);
                break;
              case CityComplianceStatus.NOTCOMPLIANT:
                this.selectedRapidGlobalInfo!.status = this.statusNotCompliant;
                break;
              case CityComplianceStatus.NOTAPPLICABLE:
                this.selectedRapidGlobalInfo!.status = this.statusNotApplicable;
                this.showComplianceError.emit(false);
                break;
              default:
            }

            this.complianceErrorMessage = s.errorMessage;

          }
          if (this.siteComplianceCheckConfig && this.companyName) {
            this.siteComplianceCheckConfig.answer = this.getComplianceCheckAnswer();
          }
        },
        error: (error) => {
          if (error.error && error.error.errorCode) {
            alert(this._localizationService.translate(error.error.errorCode));
          }
          else {
            alert(this._localizationService.translate('site_compliance_compliance_status_error'));
          }
        }
      });
    } else {
      alert(this._localizationService.translate("site_compliance_compliance_status_error"));
    }
  }

  private getProfileImageLink(userPhoto: string) {
    if (environment.isDemo) {
      this.profileImgLink = userPhoto;
    } else {
      // Regular expression to check if string is a valid UUID
      const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

      if (!regexExp.test(userPhoto)) {
        this.profileImgLink = userPhoto;
      } else {
        if (this.currentUser) {
          this._fileStorageService.download(userPhoto).subscribe(
            {
              next: file => {
                this.profileImgLink = `data:image/${file.fileType};base64,${file.file}`;
                if (this.currentUser) {
                  this.currentUser.photo = this.profileImgLink;
                  this._authService.setUser(this.currentUser);
                }

              },
              error: error => {
                if (error.error && error.error.errorCode) {
                  alert(this._localizationService.translate(error.error.errorCode));
                }
                else {
                  alert(this._localizationService.translate("site_compliance_check_error_downloading_photo"));
                }
                this.profileImgLink = "";
              }
            });
        } else {
          this.profileImgLink = "";
        }
      }
    }
  }
}
