import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { UserDocument } from 'src/app/models/user.model';
import { FileStorageService } from 'src/app/services/filestorage/filestorage.service';
import { LocalizationService } from 'src/app/components/internationalization/localization.service';

@Component({
  selector: 'app-user-documents-delete',
  templateUrl: './user-documents-delete.component.html',
  styleUrls: ['./user-documents-delete.component.scss']
})
export class UserDocumentsDeleteComponent implements OnInit {
  userDocument : UserDocument | undefined;
  currentUser : User | undefined;

  constructor(private _dialogRef: MatDialogRef<UserDocumentsDeleteComponent>,
    private _userService : UserService,
    private _fileStorageService: FileStorageService,
    private _localizationService : LocalizationService,
    @Inject(MAT_DIALOG_DATA) public data: {
      user: User | undefined, 
      userDocument: UserDocument | undefined}
    ) {}

  ngOnInit(): void {
    this.currentUser = this.data.user;
    this.userDocument = this.data.userDocument;
  }

  public dismissDialog(): void {
    this._dialogRef.close();
  }

  deleteUserDocument(){
    if (this.userDocument && this.userDocument.id && this.userDocument.fileStorageId){
      if (this.currentUser){        
        this._userService.deleteDocument(this.userDocument.id).subscribe({
          next: (_ => {
            this._fileStorageService.RemoveDocument(this.userDocument!.fileStorageId).subscribe({
              next: (_ => {
                const indx = this.currentUser!.userDocuments
                  .findIndex(v => v.id === this.userDocument!.id) ?? -1;
                this.currentUser!.userDocuments.splice(indx, indx >= 0 ? 1 : 0);
                this._dialogRef.close(this.currentUser);
              })
            });
          }),
          error: (error => {
            if (error.error && error.error.errorCode) {
              alert(this._localizationService.translate(error.error.errorCode));
            }
            else {
              alert(this._localizationService.translate('user_documents_file_remove_error'));
            }
          })
        });
      }
    }
  }
  
}