<div id="login" fxLayout="column">
    <div fxLayout="row">
        <div fxFlex="3"></div>
        <div class="logo" fxFlex="94">
            <img src="/assets/CityOnSite_Logo.png">
        </div>
    </div>
    <form [formGroup]="loginUserFormGroup">
        <div fxLayout="row">
            <div fxFlex="100">
                <div fxLayout="column" fxLayoutGap="0.5em">
                    <div fxLayout="row">
                        <div class="font-size-h1 color-city-blue">{{'loginuser_signin' | translate }}</div>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label class="color-city-blue">{{'loginuser_enteremail_field' | translate }}</mat-label>
                            <input matInput placeholder="{{'loginuser_enteremail_field' | translate }}"
                                formControlName="emailAddress" required id="input_emailaddress"
                                data-testid="input_email_address">
                            <mat-error
                                *ngIf="hasError(loginUserFormGroup, 'emailAddress')">{{getError(loginUserFormGroup,
                                'emailAddress')}}</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="signup-user">
                        <a class="color-city-blue" id="btn_signupuser" data-testid="btn_signup_user"
                            (click)="signUpUser()">
                            {{'loginuser_signup_user_prompt' | translate }} <br> {{'loginuser_signup_user_action' |
                            translate }}</a>
                    </div>
                    <div class="divider"></div>
                    <div>
                        <button type="submit" id="btn_loginusercontinue" data-testid="btn_login_user_continue"
                            mat-raised-button (click)="continue()" [disabled]="disableSubmitButton()">
                            {{'loginuser_continue' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div class="divider"></div>
    <div *ngIf="isGoogleSSOEnable || isAppleSSOEnable">
        <div class="login-divider">OR</div>
        <div class="divider"></div>
        <div *ngIf="isAppleSSOEnable">
            <button 
            id="btn_signinwithapple" 
            data-testid="btn_signinwithapple"
            class="sign-in-with-apple-google-button"
            mat-button
            (click)="signInWithApple()" >
                <img src="/assets/appleid.png" alt="Sign in with Apple" class="apple-google-button-image">
                <span class="apple-google-button-text">{{'loginuser_button_sign_in_with_apple' | translate }}</span>
            </button>
        </div>

        <div class="divider"></div>
        <div *ngIf="isAppleSSOEnable">
            <button 
            id="btn_signinwithgoogle" 
            data-testid="btn_signinwithgoogle"
            class="sign-in-with-apple-google-button"
            mat-button
            (click)="loginWithGoogle()" >
                <img src="/assets/googleLogo.png" alt="Sign in with Google" class="apple-google-button-image">
                <span class="apple-google-button-text">{{'loginuser_signin_with_google' | translate }}</span>
            </button>
        </div>
    </div>

    <div fxLayout="row" fxFlex="100" *ngIf="isMutliLanguageEnable && languages.length >= 1">
        <mat-form-field appearance="outline" style="margin-top:3vh;" fxFlex="100">
            <mat-select id="language" name="language" [(value)]='language'
                (selectionChange)="onLangSelect($event.value)">
                <mat-option *ngFor="let lang of languages" [value]="lang.code">{{lang.description}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="divider"></div>
    <div *ngIf="isWebBrowser()" fxLayout="row" style="align-self: center;" class="app_store_badge">
        <div fxLayout="col">
            <img alt='Download on the App Store' style="height: 50px;padding-top: 9px;"
                src='/assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg' (click)="installApple()">
        </div>

        <div fxLayout="col">
            <img alt='Get it on the Play Store' style="height: 60px; padding-top: -12px; "
                src='/assets/google-play-badge.png' (click)="installGoogle()">
        </div>
    </div>
    <div class="divider"></div>
    <div fxLayout="row">
        <div fxFlex="3"></div>
        <div class="logo" fxFlex="94">
            <img src="/assets/CityDowntown.png">
        </div>
    </div>
    <div class="divider"></div>
    <!--TODO Hide when running as native app-->
    <div fxLayout="row" class="version">
        <div fxLayout="col">
            {{'v' + version }}
        </div>
    </div>
</div>