import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RapidGlobalInfo, SiteInductionInfo, User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import { LocalizationService } from '../internationalization/localization.service';
import { SiteInductionAddEditComponent } from '../site-induction-add-edit/site-induction-add-edit.component';
import { RapidGlobalAddEditComponent } from '../rapid-global-add-edit/rapid-global-add-edit.component';
import { SiteInductionDeleteComponent } from '../../components/site-induction-delete/site-induction-delete.component';
import { RapidGlobalDeleteComponent } from '../../components/rapid-global-delete/rapid-global-delete.component';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { ConfigurationService } from '@app/services/configuration/configuration.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-register2',
  templateUrl: './register2.component.html',
  styleUrls: ['./register2.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
    trigger('detailInductionExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class Register2Component implements OnInit {

  rapidGlobalInfos : MatTableDataSource<RapidGlobalInfo> = new MatTableDataSource<RapidGlobalInfo>([]);
  displayedRapidGlobalColumns: string[] = ['companyName', 'status'];
  siteInductionInfos : MatTableDataSource<SiteInductionInfo> = new MatTableDataSource<SiteInductionInfo>([]);
  displayedSiteInductionColumns: string[] = ['brandName', 'status'];
  currentUser : User | undefined;
  expandedRapidElement: RapidGlobalInfo | undefined;
  expandedInductionElement: SiteInductionInfo | undefined;
  rapidGlobalModuleEnable = false;
  profileInductionModuleEnable = false;

  constructor(
    private _location: Location,
    private _authService:AuthService,
    private _router:Router,
    private _localizationService: LocalizationService,
    private _userService: UserService,
    private _addDialog: MatDialog,
    private _navigationService:NavigationService,
    private _configurationService: ConfigurationService,
  ) {
    this._navigationService.onChange(false, false);
  }

  async ngOnInit() {
    this.currentUser = await this._authService.getUser();
    if (this.currentUser){
      this.rapidGlobalInfos.data = this.currentUser.rapidGlobalInfos;
      this.siteInductionInfos.data = this.currentUser.siteInductionInfos;
    }

    this.rapidGlobalModuleEnable = await this._configurationService.getProfileRapidGlobalRequired();
    this.profileInductionModuleEnable = await this._configurationService.getProfileInductionRequired();
  }

  getStatus(item: any): string {
    let curentDate = new Date().getTime();
    let expiryDate = Date.parse(item.expiryDate);
    if (expiryDate >= curentDate)
     return "active" 
    else if (item.status === 'Compliance')
      return "inactive"
      else return "error";
  }

  async continueRegister() {

    if (this.rapidGlobalModuleEnable && this.currentUser) {
      if (this.rapidGlobalModuleEnable && this.currentUser.rapidGlobalInfos.length == 0) {
        alert(this._localizationService.translate("register_rapidglobal_required"))
        return;
      }
    }

    if (this.profileInductionModuleEnable && this.currentUser) {
      if (this.profileInductionModuleEnable && this.currentUser.rapidGlobalInfos.length == 0) {
        alert(this._localizationService.translate("register_siteinduction_required"))
        return;
      }
    }

    if (this.currentUser) {
      let updateInJson = `{"registerStatus":2}`;
      this._userService.updateRegisterStatus(this.currentUser.id, updateInJson).subscribe({
        next: (result) => {
          if (result) {
            this._router.navigate(['register/3']);
          }
        },
        error: (error) => {
          if (error.error && error.error.errorCode) {
            alert(this._localizationService.translate(error.error.errorCode));
          }
          else {
            alert(this._localizationService.translate('register_error_update_status'));
          }
        }
      })
    }

  }

  addRapidGlobal(){
    let addRapidDialog: MatDialogRef<RapidGlobalAddEditComponent>;
    const dialogConfig = {
        data: {user: this.currentUser, isEditMode: false},
        disableClose: true,
        autoFocus: false,
        maxWidth: '100vw !important',
        maxHeight: '100vw !important',
        panelClass:'details-container'
    };
    addRapidDialog = this._addDialog.open(RapidGlobalAddEditComponent, dialogConfig);
    addRapidDialog.addPanelClass('details-container');
    addRapidDialog.afterClosed().subscribe((returnValue) => {
        if (returnValue) {
          this._authService.setUser(returnValue);
          this.ngOnInit();
        }
    });
  }

  editRapidGlobal(item: RapidGlobalInfo | undefined){
    let addRapidDialog: MatDialogRef<RapidGlobalAddEditComponent>;    
    const dialogConfig = {
      data: {user: this.currentUser, rapid: item, isEditMode: true},
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw !important',
      maxHeight: '100vw !important',
      panelClass:'details-container'
    };
    addRapidDialog = this._addDialog.open(RapidGlobalAddEditComponent, dialogConfig);
    addRapidDialog.addPanelClass('details-container');
    addRapidDialog.afterClosed().subscribe((returnValue) => {
      if (returnValue) {
        this._authService.setUser(returnValue);
        this.ngOnInit();
      }
    });
  }

  deleteRapidGlobal(item: RapidGlobalInfo | undefined){
    let deleteRapidDialog: MatDialogRef<RapidGlobalDeleteComponent>;
    const dialogConfig = {
      data: {user: this.currentUser, rapid: item},
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw !important',
      maxHeight: '100vw !important',
      panelClass:'details-container'
    };
    deleteRapidDialog = this._addDialog.open(RapidGlobalDeleteComponent, dialogConfig);
    deleteRapidDialog.addPanelClass('details-container');
    deleteRapidDialog.afterClosed().subscribe((returnValue) => {
      if (returnValue) {
        this._authService.setUser(returnValue);
        this.ngOnInit();
      }
    });
  }
  
  addSiteInduction(){
    let addInductionDialog: MatDialogRef<SiteInductionAddEditComponent>;
    const dialogConfig = {
      data: {user: this.currentUser, isEditMode: false},
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw !important',
      maxHeight: '100vw !important',
      panelClass:'details-container'
    };
    addInductionDialog = this._addDialog.open(SiteInductionAddEditComponent, dialogConfig);
    addInductionDialog.addPanelClass('details-container');
    addInductionDialog.afterClosed().subscribe((returnValue) => {
        if (returnValue) {
          this._authService.setUser(returnValue);
          this.ngOnInit();
        }
    });
  }

  editSiteInduction(item: SiteInductionInfo | undefined){
    let addInductionDialog: MatDialogRef<SiteInductionAddEditComponent>;
    const dialogConfig = {
      data: {user: this.currentUser, induction: item, isEditMode: true},
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw !important',
      maxHeight: '100vw !important',
      panelClass:'details-container'
    };
    addInductionDialog = this._addDialog.open(SiteInductionAddEditComponent, dialogConfig);
    addInductionDialog.addPanelClass('details-container');
    addInductionDialog.afterClosed().subscribe((returnValue) => {
      if (returnValue) {
        this._authService.setUser(returnValue);
        this.ngOnInit();
      }
    });
  }

  deleteSiteInduction(item: SiteInductionInfo | undefined){
    let deleteInductionDialog: MatDialogRef<SiteInductionDeleteComponent>;
    const dialogConfig = {
      data: {user: this.currentUser, induction: item},
      disableClose: true,
      autoFocus: false,
      maxWidth: '100vw !important',
      maxHeight: '100vw !important',
      panelClass:'details-container'
    };
    deleteInductionDialog = this._addDialog.open(SiteInductionDeleteComponent, dialogConfig);
    deleteInductionDialog.addPanelClass('details-container');
    deleteInductionDialog.afterClosed().subscribe((returnValue) => {
      if (returnValue) {
        this._authService.setUser(returnValue);
        this.ngOnInit();
      }
    });
  }

  back(){
    this._location.back();
  }
  
}