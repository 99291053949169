import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Company, RapidGlobalInfo, User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user/user.service';
import { LocalizationService } from '../internationalization/localization.service';

@Component({
  selector: 'app-rapid-global-add',
  templateUrl: './rapid-global-add-edit.component.html',
  styleUrls: ['./rapid-global-add-edit.component.scss']
})
export class RapidGlobalAddEditComponent implements OnInit {

  public rapidFormGroup! : UntypedFormGroup;
  enableSubmitButton : boolean = false;
  showSuccessRapidStatus : boolean = false;
  showFailedRapidStatus : boolean = false;
  rapidGlobalInfo : RapidGlobalInfo | undefined;
  currentUser : User | undefined;
  isEditMode : boolean = false;
  companies : Company[] = [];
  companyIdSelected : string | undefined;

  constructor(private _dialogRef: MatDialogRef<RapidGlobalAddEditComponent>,
              private _localizationService : LocalizationService,
              private _formBuilder: UntypedFormBuilder,
              private _userService : UserService,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.currentUser = this.data.user;
    this.rapidGlobalInfo = this.data.rapid;
    this.isEditMode = this.data.isEditMode;

    if(this.currentUser?.companies){
      this.companies = this.currentUser?.companies;
    }

    this.rapidFormGroup = this.initFormGroup();
  }

  public dismissDialog(): void {
    this._dialogRef.close();
  }

  private initFormGroup(): UntypedFormGroup {
    const rapidForm = this._formBuilder.group({
      companyId: new UntypedFormControl(this.rapidGlobalInfo?.companyId),
      businessName: new UntypedFormControl({ value: this.rapidGlobalInfo?.companyName, disabled: false })
    });
    return rapidForm;
  }

  public hasError(form: UntypedFormGroup, controlName: string): boolean {
    const validationOutput = this.getError(form, controlName);
    return validationOutput !== '';
  }

  public getError(form: UntypedFormGroup, controlName: string): string {
    switch (controlName) {
      case 'businessName':
        if (this.formHasError(form, controlName, 'required')) {
          return this._localizationService.translate('register_businessname_required');
        } 

        if (this.formHasError(form, controlName, 'exists')) {
          return this._localizationService.translate('register_businessname_exists');
        } 
        break;
    }
    return '';
  }

  private formHasError(form: UntypedFormGroup, controlName: string, errorName: string): boolean {
    return form.controls[controlName].hasError(errorName);
  }

  disableSubmitButton(){
    return !this.rapidFormGroup.valid || !this.enableSubmitButton;
  }

  addRapidGlobal(){
    this.rapidGlobalInfo = this.getPostPayload();
    if (this.currentUser){
      this._userService.addRapidGlobal(this.currentUser.id, this.rapidGlobalInfo).subscribe({
        next: (response => {
          this.currentUser?.rapidGlobalInfos.push(response);
          this._dialogRef.close(this.currentUser);
        }),
        error: (error => {
          if (error.error && error.error.errorCode) {
            alert(this._localizationService.translate(error.error.errorCode));
          }
          else {
            alert(this._localizationService.translate('register_rapidglobalid_saveerror'));
          }
        }) 
      });
    }
  }

  editRapidGlobal(){
    this.rapidGlobalInfo = this.getPutPayload();
    if (this.currentUser && this.rapidGlobalInfo && this.rapidGlobalInfo.id){
      this._userService.editRapidGlobal(this.rapidGlobalInfo.id, this.rapidGlobalInfo).subscribe({
        next: (response => {
          if (response && this.currentUser?.rapidGlobalInfos){
            const indx = this.currentUser?.rapidGlobalInfos
              .findIndex(v => v.id === this.rapidGlobalInfo?.id) ?? -1;
            if (indx >= 0) 
              this.currentUser.rapidGlobalInfos[indx] = response;
            this._dialogRef.close(this.currentUser);
          }
        }),
        error: (error) => {
          if (error.error && error.error.errorCode) {
            alert(this._localizationService.translate(error.error.errorCode));
          }
          else {
            alert(this._localizationService.translate('register_rapidglobal_update_error'));
          }
        }
      });
    }
  }

  validateRapid(){

    this.showSuccessRapidStatus = false;
    this.enableSubmitButton = false;

    const businessName = this.rapidFormGroup.get('businessName');

    if (this.currentUser){
      if(businessName?.value){
        if(this.currentUser.rapidGlobalInfos.some(rapid => rapid.companyName.toLowerCase() == businessName.value.toLowerCase() && rapid.id != this.rapidGlobalInfo?.id)){
          businessName.setErrors({ 'exists': true })
        }else{
          this.enableSubmitButton = true;
        }
      }
    }
    
  }

  getPostPayload() : RapidGlobalInfo {
    const businessName = this.rapidFormGroup.get('businessName');
    
    const payload : RapidGlobalInfo = {
      id : undefined,
      companyId : undefined,
      companyName : businessName?.value,
      status: '',
      statusId: -1,
      expiryDate: undefined,
      cityComplianceId: undefined
    }
    return payload;
  }

  getPutPayload() : RapidGlobalInfo | undefined {
    if (this.rapidGlobalInfo){
      const businessName = this.rapidFormGroup.get('businessName');
      if(businessName)
        this.rapidGlobalInfo.companyName = businessName?.value;
     
      return this.rapidGlobalInfo;
    }
    return;
  }

  compareCategoryObjects(object1: any, object2: any) {
    return object1 && object2 && object1.id == object2.id;
  }
}
