import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SignInSignOut, User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LocalizationService } from '../internationalization/localization.service';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { SiteService } from 'src/app/services/site/site.service';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { ClosedownService } from 'src/app/services/closedown/closedown.service';
import { FastLinkType } from '../../shared/enums';
import { UserService } from 'src/app/services/user/user.service';
import { Subject, fromEvent, takeUntil } from 'rxjs';
import { GeolocationService } from '@app/services/geolocation/geolocation.service';
import { LoaderService } from '@app/services/loader/loader.service';
import { NgSignaturePadOptions, SignaturePadComponent } from '@almothafar/angular-signature-pad';
import { error } from 'console';



@Component({
    selector: 'app-site-sign-out',
    templateUrl: './site-sign-out.component.html',
    styleUrls: ['./site-sign-out.component.scss']
})


export class SiteSignOutComponent implements OnInit {
    private unsubscriberBackBtn: Subject<void> = new Subject<void>();

    public siteSignOutFormGroup!: UntypedFormGroup;
    public signOutSignature: any = { question: { questionType: { name: "Signature" } }, name: "SignOut" };
    currentUser: User | undefined;
    firstname: string | undefined;
    fullname: string | undefined;
    siteName: string | undefined;
    siteId: string | undefined;
    inductionRequired: boolean = false;

    @ViewChild('signatureWrapper') signatureWrapper: ElementRef | undefined;
    @ViewChild('signature')
    public signaturePad: SignaturePadComponent;
    
    signaturePadOptions: NgSignaturePadOptions = { 
        minWidth: 2,
        canvasWidth: window.innerWidth * 0.92,
        canvasHeight: 150,
        canvasBackground: 'white'
    };

    signatureImg: any;
    constructor(private _authService: AuthService,
        private _localizationService: LocalizationService,
        private formBuilder: UntypedFormBuilder,
        private _siteService: SiteService,
        public _router: Router,
        private _navigationService: NavigationService,
        private _closedownService: ClosedownService,
        private _userService: UserService,
        private _geolocationService: GeolocationService,
        private _loader: LoaderService) {

        this._navigationService.onChange(false, false);
        this.initBrowserBackButton();

        this._authService.getUser().then(
            user => {
                this.currentUser = user;
                this.firstname = user.firstName;
                this._closedownService.setCurrentUser(user);
                this.loadSite();

            });
        this.siteSignOutFormGroup = this.initSiteSignOutForm();

        this._authService.getTokenFromStorage().then(bearer => {
            _closedownService.setBearerToken(bearer);
        });
    }

    private initSiteSignOutForm(): UntypedFormGroup {
        const siteSignOutForm = this.formBuilder.group({
            firstName: new UntypedFormControl(undefined, [Validators.required]),
            signOutSignature: new UntypedFormControl(undefined, [Validators.required])
        });

        return siteSignOutForm;
    }

    async ngOnInit(): Promise<void> {
        window.scrollTo(0, 0);
        var processFlow = await this._closedownService.getProcessFlow();
        processFlow.linkType = FastLinkType.Job;
        this._closedownService.setProcessFlow(processFlow);

        if(processFlow.token)
            this._closedownService.setWithFastLink(true);

    }

    ngAfterViewInit(): void {
        this.setSignaturePad();
    }

    public submitSiteSignOut(): void {
        if (this.currentUser) {
            if (confirm(this._localizationService.translate('sitesignout_dialog_confirm'))) {
                let postSignOut: SignInSignOut = {
                    id: undefined,
                    userId: this.currentUser.id,
                    signInDateTime: new Date(0),
                    signOutDateTime: new Date(),
                    signOutSignature: btoa(this.signatureImg),
                    complianceCompany: "",
                    complianceCompanyRapidGlobalId: "",
                    inductionId: "",
                    inductionQnA: "",
                    reasonForVisit: "",
                    signInType: "",
                    siteId: this.siteId ?? "",
                    visitorType: "",
                    companyRapidGlobalGuid: "",
                    inductionGuid: "",
                    userDocumentIds:[],
                    complianceCompanyRapidGlobalStatus: -1
                }

                let latestLocation = this._geolocationService.getLatestLocation();
                if (latestLocation === null) {
                    this._loader.show();
                    this._geolocationService.setLatestLocation().then(s =>{

                            if (s && s.coords.latitude && s.coords.longitude) {
                                postSignOut.SignOutLatLon = s.coords.latitude + "," + s.coords.longitude;
                            }

                    }).finally(() =>{
                        this._loader.hide();
                        this.postSignOut(postSignOut);
                    });

                }else{

                    if (latestLocation.coords.latitude && latestLocation.coords.longitude) {
                        postSignOut.SignOutLatLon = latestLocation.coords.latitude + "," + latestLocation.coords.longitude;
                    }

                    this.postSignOut(postSignOut);
                }
            }
        }
    }

    public postSignOut(postSignOut: SignInSignOut){

        this._userService.addSignOut(postSignOut).subscribe({
            next : (signout) => {
                this._siteService.removeSignedInSite().then(r => {
                    this._siteService.removeVisitReasons().then(async r1 => {
                        this._closedownService.removeProcessFlow().then(r => {
                            this._userService.removeSignIn().then(r => {
                                this._userService.removeOutOfRange().then(r => {
                                    this._userService.clearOutOfRangeSettings().then(r => {
                                        this._router.navigate(['signin']);
                                    })
                                })
                            })
                        });
                    })
                })
            },
            error: (error) => {
                if (error.error && error.error.errorCode) {
                   alert(this._localizationService.translate(error.error.errorCode));
                }
                else {
                   alert(this._localizationService.translate('siteinductioncompleted_error_save_sign_out_info'));
                }
            }
        });

    }

    public disableSubmitButton(): boolean {
        return (this.signatureImg == undefined || this.signatureImg == null || this.signatureImg == '');
        //return !this.siteSignOutFormGroup.valid;
    }

    public hasError(form: UntypedFormGroup, controlName: string): boolean {
        const validationOutput = this.getError(form, controlName);
        return validationOutput !== '';
    }

    public getError(form: UntypedFormGroup, controlName: string): string {
        switch (controlName) {
            case 'firstName':
                if (this.formHasError(form, controlName, 'required')) {
                    return this._localizationService.translate('sitesignout_name_required');
                }
                else if (this.formHasError(form, controlName, 'invalidFirstName')) {
                    return this._localizationService.translate('sitesignout_name_not_valid');
                }
                break;

        }
        return '';
    }

    private formHasError(form: UntypedFormGroup, controlName: string, errorName: string): boolean {
        return form.controls[controlName].hasError(errorName);
    }

    drawComplete(event: MouseEvent | Touch) {
        this.signatureImg = this.signaturePad?.toDataURL();
        //this.question.answer = this.signatureImg;
    }

    drawStart(event: MouseEvent | Touch) {
    }

    loadSite() {
        this._siteService.getSignedInSite().then(site => {
            this.siteName = site.name;
            this.siteId = site.id;
            this.inductionRequired = site.isInductionRequired;
        });
    }

    initBrowserBackButton() {
        history.pushState(null, '');
        fromEvent(window, 'popstate').pipe(takeUntil(this.unsubscriberBackBtn)).subscribe((_) => {
            history.pushState(null, '');
            this.back();
        });
    }

    back() {
        this._router.navigate(['siteinductioncompleted']);
    }

    get getSiteId(): string | undefined {
        return this.siteId;
    }

    get getInductionRequired(): boolean {
        return this.inductionRequired;
    }

    setSignaturePad() {
        if (this.signatureWrapper && this.signaturePad) {
          this.signaturePad.getCanvas().width = this.signatureWrapper.nativeElement.clientWidth - 1;
          this.signaturePad.getCanvas().height = this.signatureWrapper.nativeElement.clientHeight - 1;
        }
    }

    resizeSignaturePad() {
        this.setSignaturePad();
        this.signaturePad?.clear();
        this.signatureImg = undefined;
    }
}
